import ErrorBoundary from 'react-native-error-boundary'
import { SafeAreaView, ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import { useIsFocused } from '@react-navigation/native'
import styles from './styles'
import LoginForm from './components/LoginForm'
import AppDownloadMessage from '../AppDownloadMessage'
import AppLogo from './components/AppLogo'
import ForgotPasswordButton from '$components/ForgotPasswordButton'
import BorderedButton from '$components/BorderedButton'
import { i18n, currentRegion } from '$localization/config.js'
import {
  setLanguage,
  setBottomTabBarTitles,
  setShowLoadingView,
  setInviteID,
  setIsOnboarding,
} from '$redux/defaults/actions'
import { IS_MOBILE, IS_WEB } from '$utils/Platforms'
import openInNewWebTab from '$utils/openInNewWebTab.js'
import { APP_STORE_LINK, PLAY_STORE_LINK } from '$utils/globalVariables.js'
import Biometrics from './components/Biometrics'
import { clearReduxDataAfterLogout, fetchUserID } from './helpers'
import { simpleStorage } from '$utils'
import useAuth from '$hooks/useAuth'
import ROUTES from '$navigation/constants/routes'
import { ANDROID, IOS } from '$constants/platforms'
import AppInfo from '../AppInfo'
import {
  ERROR_TYPE,
  LOGIN_CONTAINER_NATIVE_ID,
  STORAGE_ITEM_NAMES,
} from './constants'

function LoginScreen() {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const { handleLoginUser } = useAuth()

  const storage = simpleStorage()
  const [showAppDownload, setShowAppDownload] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [loginError, setLoginError] = useState(null)
  const [deviceOS, setDeviceOS] = useState(null)
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const selectedLanguage = useSelector((state) => state.store.selectedLanguage)

  const setCurrentLanguage = (data) => dispatch(setLanguage(data))
  const setLoadingView = (data) => dispatch(setShowLoadingView(data))
  const resetBottomTabBarTitles = () => dispatch(setBottomTabBarTitles())
  const saveInviteID = (id) => dispatch(setInviteID(id))
  const setIsOnboardingFlag = (data) => dispatch(setIsOnboarding(data))

  const handleNavigateToFamilyInviteScreen = () =>
    navigation.navigate('SignupFamilyInviteNavigator', {
      screen: 'SignupFamilyInviteCode',
    })

  const showAppDownloadMessage = async () => {
    if (
      IS_MOBILE() ||
      APP_STORE_LINK?.length === 0 ||
      PLAY_STORE_LINK?.length === 0
    )
      return

    try {
      const DeviceInfo = require('react-native-device-info')
      const deviceBaseOS = (await DeviceInfo.getBaseOs()).toLowerCase()
      setDeviceOS(deviceBaseOS)
      setShowAppDownload(deviceBaseOS === IOS || deviceBaseOS === ANDROID)
    } catch (err) {
      console.log(err)
    }
  }

  const handleNavigateToLanguageSettings = () => {
    const handleLanguageChange = (lang) => {
      const newLocale = `${lang}-${currentRegion}`

      i18n.locale = newLocale
      setCurrentLanguage(lang)
      resetBottomTabBarTitles()

      if (IS_WEB()) {
        localStorage?.setItem('currentLanguage', newLocale)
      }
    }

    navigation.navigate('SettingsNavigator', {
      screen: ROUTES.SelectLanguage.name,
      onLanguageSelected: (item) => handleLanguageChange(item),
    })
  }

  const handleIsInputValid = () => {
    const validator = require('email-validator')
    const isEmailInvalid = email && !validator.validate(email)

    const isEmailEmpty = !email?.length
    const isPasswordEmpty = !password?.length

    if (isEmailInvalid || isEmailEmpty) {
      setLoginError(ERROR_TYPE.EMAIL)
      return false
    }

    if (isPasswordEmpty) {
      setLoginError(ERROR_TYPE.EMPTY)
      return false
    }

    setLoginError(null)
    return true
  }

  const handleLoginButton = async () => {
    setLoadingView(true)

    try {
      if (!handleIsInputValid()) return

      if (rememberMe) {
        storage.setItem(STORAGE_ITEM_NAMES.EMAIL, email, false)
        storage.setItem(STORAGE_ITEM_NAMES.ACCOUNTSTORE, rememberMe)
      } else {
        storage.removeItem(STORAGE_ITEM_NAMES.EMAIL)
        storage.removeItem(STORAGE_ITEM_NAMES.ACCOUNTSTORE)
      }

      await handleLoginUser(navigation, email, password)
    } catch (error) {
      console.log(
        '🚀 \n\n file: LoginScreen \n\n handleLoginButton= \n\n error:',
        error
      )
    } finally {
      setLoadingView(false)
    }
  }
  const getUserDetailsFromStorage = async () => {
    try {
      const inviteID = await fetchUserID()

      setIsOnboardingFlag(true)

      if (inviteID) {
        saveInviteID(inviteID)
      }

      if (isFocused) {
        storage.setItem(STORAGE_ITEM_NAMES.ON_SPLASH, true)
        clearReduxDataAfterLogout()
        resetBottomTabBarTitles()
      }

      storage.getItem(STORAGE_ITEM_NAMES.EMAIL).then((emailProp) => {
        const emailPayload = emailProp && emailProp !== '' ? emailProp : null

        setEmail(emailPayload)
      })

      storage
        .getItem(STORAGE_ITEM_NAMES.ACCOUNTSTORE)
        .then((rememberMeProp) => {
          const rememberMePayload = rememberMeProp === 'true'

          setRememberMe(rememberMePayload)
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showAppDownloadMessage()
    getUserDetailsFromStorage()
  }, [])

  return (
    <ErrorBoundary>
      <SafeAreaView style={styles.screenContainer(colorSettings)}>
        <ScrollView contentContainerStyle={styles.container}>
          <View style={styles.container} nativeID={LOGIN_CONTAINER_NATIVE_ID}>
            <AppLogo />

            <LoginForm
              email={email ?? ''}
              setEmail={setEmail}
              setPassword={setPassword}
              password={password ?? ''}
              loginError={loginError}
              setRememberMe={setRememberMe}
              rememberMe={rememberMe}
              loginButtonAction={handleLoginButton}
            />

            <ForgotPasswordButton
              onPress={() => navigation.navigate('ForgotPWSelectOption')}
            />

            <BorderedButton
              buttonText={i18n.t('login')?.capitalize}
              isFilled
              onPress={() => handleLoginButton()}
            />
            <BorderedButton
              buttonText={i18n.t('sign_up')?.capitalize}
              onPress={handleNavigateToFamilyInviteScreen}
            />

            <Biometrics />
          </View>

          <AppInfo openLanguageSettings={handleNavigateToLanguageSettings} />
        </ScrollView>

        {showAppDownload && (
          <AppDownloadMessage
            isOpen={false}
            onPressPrimary={() =>
              deviceOS === IOS
                ? openInNewWebTab(APP_STORE_LINK)
                : openInNewWebTab(PLAY_STORE_LINK)
            }
          />
        )}
      </SafeAreaView>
    </ErrorBoundary>
  )
}

export default LoginScreen
