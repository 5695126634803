import { StyleSheet } from 'react-native'

const BLACK = 'black'
const WHITE = 'white'

export default StyleSheet.create({
  innerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  nextButtonText: (colorSettings) => ({
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    color: colorSettings?.bottom_next_btn_text || BLACK,
  }),
  button: (colorSettings) => ({
    borderWidth: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 30,
    minHeight: 60,
    marginBottom: 10,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 35,
    paddingVertical: 10,
    backgroundColor: colorSettings?.bottom_next_btn_enabled || WHITE,
  }),
  image: {
    width: 150,
    height: 150,
    resizeMode: 'contain',
    marginVertical: 20,
  },
  text: {
    fontFamily: 'karla-bold',
    fontSize: 30,
    textAlign: 'center',
    marginVertical: 20,
    paddingBottom: 20,
  },
})
