import React, { useEffect, useState, useCallback } from 'react'
import { Text, Image, ScrollView, View } from 'react-native'
import { i18n } from '$localization/config.js'

import RoundedButton from '$components/RoundedButton'
import NextButtonAbsolute from '$components/NextButtonAbsolute'
import FlexContainer from '$components/FlexContainer'

import { DefaultFullHeight } from '$assets/styles/global.styles.js'
import { IS_WEB, IS_MOBILE_DEVICE_WEB_BROWSER } from '$utils/Platforms.js'

import styles from './styles'

const DEFAULTS = {
  SURVEY_STRING: i18n.t('survey_complete').title ?? 'Survey Complete',
  EDIT_TEXT: i18n.t('edit').uppercase ?? 'EDIT',
  GO_TO_SURVEYS_TEXT: i18n.t('go_to_surveys').uppercase ?? 'GO TO SURVEYS',
  ACTIVE_OPACITY: 0.2,
  NEXT_BUTTON_TEXT: i18n.t('next').uppercase ?? 'NEXT',
  WHITE_COLOR: '#fff',
  NEXT_BUTTON_HEIGHT: 60,
}

function SurveyCompleteScreen({
  colorSettings,
  handleEditCompletedSurvey = () => null,
  onExitSurveyClicked = () => null,
  isOnboarding,
  surveyName,
}) {
  const [scrollViewHeight, setScrollViewHeight] = useState(DefaultFullHeight())

  const screenResizeHandler = useCallback(() => {
    setScrollViewHeight(DefaultFullHeight())
  }, [])

  useEffect(() => {
    if (IS_WEB() && !IS_MOBILE_DEVICE_WEB_BROWSER()) {
      window.addEventListener('resize', screenResizeHandler)
    }

    return () => {
      if (IS_WEB() && !IS_MOBILE_DEVICE_WEB_BROWSER()) {
        window.removeEventListener('resize', screenResizeHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const surveyCompleteText = isOnboarding
    ? `${DEFAULTS.SURVEY_STRING}\n"${surveyName}"`
    : DEFAULTS.SURVEY_STRING

  return (
    <>
      <ScrollView
        style={{
          height: isOnboarding
            ? scrollViewHeight
            : scrollViewHeight + DEFAULTS.NEXT_BUTTON_HEIGHT,
          backgroundColor:
            colorSettings?.onboarding_bgcolor ?? DEFAULTS.WHITE_COLOR,
        }}
        contentContainerStyle={styles.innerContainer}
      >
        <Image
          source={require('$assets/images/new_assets/illus-survey-complete.png')}
          style={styles.image}
        />

        <Text style={styles.text}>{surveyCompleteText}</Text>

        <FlexContainer>
          {!isOnboarding && (
            <RoundedButton
              buttonStyle={styles.button(colorSettings)}
              activeOpacity={DEFAULTS.ACTIVE_OPACITY}
              onPress={handleEditCompletedSurvey}
            >
              <Text style={styles.nextButtonText(colorSettings)}>
                {DEFAULTS.EDIT_TEXT}
              </Text>
            </RoundedButton>
          )}

          {!isOnboarding && (
            <RoundedButton
              buttonStyle={styles.button(colorSettings)}
              activeOpacity={DEFAULTS.ACTIVE_OPACITY}
              onPress={onExitSurveyClicked}
            >
              <Text style={styles.nextButtonText(colorSettings)}>
                {DEFAULTS.GO_TO_SURVEYS_TEXT}
              </Text>
            </RoundedButton>
          )}
        </FlexContainer>
      </ScrollView>

      {isOnboarding && (
        <NextButtonAbsolute
          buttonText={i18n.t('survey_btn_label_next')}
          onPressAction={onExitSurveyClicked}
        />
      )}

      <View />
    </>
  )
}

export default SurveyCompleteScreen
