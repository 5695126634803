const SURVEY_STATE = {
  RENDERING: 'rendering',
  FOUND: 'found',
  NOT_FOUND: 'not_found',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
}

const FIELD_TYPES = {
  LABEL: 'label',
  TEXT_SHORT: 'text_short',
  TEXT_LONG: 'text_long',
  INTEGER: 'integer',
  FLOAT: 'float',
  YES_OR_NO: 'yes_or_no',
  SELECT_ALL_THAT_APPLY: 'select_all_that_apply',
  SELECT_ONE: 'select_one',
  PHONE: 'phone',
  LONG_LIST_SEARCH: 'long_list_search',
  DATE: 'date',
  TOGGLE: 'toggle',
}

const MILISECOND = 100
const DEFAULT_SURVEY_DELAY = MILISECOND * 2

const LOADING_PADDING_TOP = 100

export { SURVEY_STATE, FIELD_TYPES, DEFAULT_SURVEY_DELAY, LOADING_PADDING_TOP }
